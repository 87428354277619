<template lang="pug">
  include ../../../configs/template
  div.seafarerInfoList.row.pt-6
    div.col-sm-12.col-md-6
      +field-validation('sailorDocument.serial_passport', 'serialAndNum', '["required"]')
    div.col-sm-12.col-md-6
      +field-validation('sailorDocument.tax_number', 'taxNumber', '["required", "minLength", "maxLength"]')
    PhoneNumberField(v-model="phone_number" :defaultValue="phone_number" :error-messages="$valid.validation($v.phone_number, ['minLength','maxLength','required', 'numeric'])").col-sm-12.col-md-6.col-lg-4.col-xl-3
    PhoneNumberField(v-model="telegram" :defaultValue="telegram" :error-messages="$valid.validation($v.telegram, ['minLength','maxLength', 'numeric'])", label="Telegram").col-sm-12.col-md-6.col-lg-4.col-xl-3
    PhoneNumberField(v-model="viber" :defaultValue="viber" :error-messages="$valid.validation($v.viber, ['minLength','maxLength', 'numeric'])", label="Viber").col-sm-12.col-md-6.col-lg-4.col-xl-3
    div.d-flex.justify-center.w-100.py-5
      v-btn(:loading="buttonLoader" color="success" @click="validationCheck")  {{ $t('save') }}
</template>

<script>
import { contacts } from '@/configs/constants'
import { newCrewingManagersEdit } from '@/mixins/validationRules'
import { mapActions } from 'vuex'
export default {
  name: 'newCrewingManagersEdit',
  components: {
    PhoneNumberField: () => import('@/components/atoms/PhoneNumberField')
  },
  props: {
    sailorDocument: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      phone_number: null,
      telegram: null,
      viber: null,
      buttonLoader: false
    }
  },
  validations () { return newCrewingManagersEdit() },
  mounted () {
    this.setContactData()
  },
  methods: {
    ...mapActions(['updateBecomingAgentStatement']),
    /** Set contact models depends on value type */
    setContactData () {
      if (this.sailorDocument.contact_info && this.sailorDocument.contact_info.length) {
        for (const contact of this.sailorDocument.contact_info) {
          Object.entries(contacts).forEach(([key, value]) => { if (contact.type_contact === value.id) this[key] = contact.value })
        }
      }
    },

    /** Check field entries before submit */
    validationCheck () {
      if (this.$v.$invalid) {
        return this.$v.$touch()
      } else this.editCrewingManagerStatement()
    },

    /** Edit agent application */
    async editCrewingManagerStatement () {
      this.buttonLoader = true
      const body = {
        tax_number: this.sailorDocument.tax_number,
        serial_passport: this.sailorDocument.serial_passport,
        contact_info: [
          { type_contact: contacts.telegram.id, value: this.telegram },
          { type_contact: contacts.viber.id, value: this.viber },
          { type_contact: contacts.phone_number.id, value: this.phone_number }
        ]
      }
      const response = await this.updateBecomingAgentStatement({ id: this.sailorDocument.id, body })
      this.buttonLoader = false
      if (response?.code === 200) {
        this.$notification.success('newCrewingManagersEdited')
        this.$store.commit('updateDataSailor', { type: 'newCrewingManagers', value: response.data })
      } else if (response?.code === 400) {
        if (response?.data.contact_info[0] === 'email is used') {
          this.$notification.error('useAnotherEmail')
        }
      }
    }
  }
}
</script>
