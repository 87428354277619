<template lang="pug">
  include ../../../configs/template
  div.seafarerInfoList
    div.col-sm-12.col-md-6.col-lg-4
      +data-info('fullName','fullName')
    div.col-sm-12.col-md-6.col-lg-4
      +data-info('taxNumber','sailorDocument.tax_number')
    div.col-sm-12.col-md-6.col-lg-4
      +data-info('serialAndNum','sailorDocument.serial_passport')
    div.col-sm-12.col-md-6
      +data-info('group','sailorDocument.crewing[labelName]')
    div.col-sm-12.col-md-6
      +data-info('city','sailorDocument.city')
    div.col-sm-12.col-md-6
      +data-info('createDate','sailorDocument.date_create')
    div(v-if="sailorDocument.contact_info.length")
      div(v-for="record of sailorDocument.contact_info" :key="record.id").col-sm-12.col-md-6.col-lg-3.text-left.p-0
        span(v-if="record.type_contact === contacts.phone_number.id")
          +data-info('phoneNumber', 'record.value')
        span(v-if="record.type_contact === contacts.telegram.id")
          +data-info('Telegram', 'record.value')
        span(v-if="record.type_contact === contacts.viber.id")
          +data-info('Viber', 'record.value')
        span(v-if="record.type_contact === contacts.email.id")
          +data-info('Viber', 'record.value')
    div
      +data-info-status('status', 'sailorDocument.status_document[labelName]', 'getStatus(sailorDocument.status_document.id)')
</template>

<script>
import { getStatus } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { mapState } from 'vuex'
import { contacts } from '@/configs/constants'

export default {
  name: 'newCrewingManagersInfo',
  props: {
    sailorDocument: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      checkAccess,
      getStatus,
      contacts
    }
  },
  computed: {
    ...mapState({
      labelName: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    }),
    fullName () {
      return `${this.sailorDocument.last_name} ${this.sailorDocument.first_name} ${this.sailorDocument.middle_name}`
    }
  }
}

</script>
