import NewCrewingManagersInfo from '@/views/NewCrewingManagers/NewCrewingManagersInfo/NewCrewingManagersInfo.vue'
import NewCrewingManagersEdit from '@/views/NewCrewingManagers/NewCrewingManagersEdit/NewCrewingManagersEdit.vue'
import NewCrewingManagersEditStatus from '@/views/NewCrewingManagers/NewCrewingManagersEditStatus/NewCrewingManagersEditStatus.vue'
import ViewPhotoList from '@/components/atoms/ViewPhotoList.vue'
import { viewDetailedComponent, back, deleteConfirmation } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { mapState } from 'vuex'

export default {
  name: 'newCrewingManagersDocument',
  components: {
    NewCrewingManagersInfo,
    NewCrewingManagersEdit,
    NewCrewingManagersEditStatus,
    ViewPhotoList
  },
  data () {
    return {
      type: 'newCrewingManagers',
      viewDetailedComponent,
      deleteConfirmation,
      checkAccess,
      back
    }
  },
  computed: {
    ...mapState({
      id: state => state.sailor.sailorId,
      labelName: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    }),
    documentID () {
      return this.$route.params.documentID
    },
    sailorDocument () {
      return this.$store.getters.sailorDocumentByID({ type: 'newCrewingManagers', id: Number(this.documentID) }) || {}
    }
  }
}
