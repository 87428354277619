import { hideDetailed } from '@/mixins/main'
import { mapState } from 'vuex'

export default {
  name: 'newCrewingManagersEditStatus',
  props: {
    sailorDocument: Object
  },
  data () {
    return {
      crewing: null,
      status: this.sailorDocument.status_document,
      buttonLoader: false,
      hideDetailed
    }
  },
  computed: {
    ...mapState({
      labelName: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      crewingCompanies: state => state.crewing.crewingCompanies
    }),
    mappingStatuses () {
      return this.$store.getters.statusChoose('StatementCrewingManager')
    }
  },
  methods: {
    /** Save new status for agent application */
    saveEditedCrewingManagerStatement () {
      this.buttonLoader = true
      const body = {
        status_document: this.status.id
      }
      if (this.status.id === 64) {
        body.crewing = this.crewing.id
      }
      this.$api.patch(`api/v1/crewing/statement_crew_manager/${this.sailorDocument.id}/`, { body }).then(response => {
        this.buttonLoader = false
        switch (response.code) {
          case 200:
            this.$notification.success('newCrewingManagersEdited')
            this.$store.commit('updateDataSailor', { type: 'newCrewingManagers', value: response.data })
            break
          case 400:
            if (response.data[0] === 'user was not created') {
              this.$notification.error('notCreatedUser')
            } else if (response.data[0] === 'Please enter your email address') {
              this.$notification.error('enterYourEmail')
            } else if (response.data[0] === 'Please use another email address') {
              this.$notification.error('useAnotherEmail')
            }
            break
          case 500:
            this.$notification.error('notify.title.error')
            break
        }
      })
    }
  }
}
